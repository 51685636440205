import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Box, ButtonBase } from '@mui/material';

// project imports
import LogoSection from '../LogoSection';
import SearchSection from './SearchSection';
import ProfileSection from './ProfileSection';
import NotificationSection from './NotificationSection';
import { useIdleTimer } from 'react-idle-timer';

// assets
import { IconMenu2 } from '@tabler/icons';
import { useState, useEffect } from 'react';

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = ({ handleLeftDrawerToggle }) => {
    const theme = useTheme();
    const [remaining, setRemaining] = useState(0);
    const [state, setState] = useState('Active');
    const [count, setCount] = useState(0);
  
    const onIdle = () => {
      localStorage.clear();
      window.location.replace(`${process.env.REACT_APP_VERIFICATION_V2_URL}login`);
    };
  
    const onActive = () => {
      setState('Active');
    };
  
    const onAction = () => {
      setCount(count + 1);
    };
  
    const { getRemainingTime } = useIdleTimer({
      onIdle,
      onActive,
      onAction,
      timeout: 30 * 60 * 1000,
      throttle: 500,
    });
  
    useEffect(() => {
      const interval = setInterval(() => {
        setRemaining(Math.ceil(getRemainingTime() / 1000));
      }, 500);
  
      return () => {
        clearInterval(interval);
      };
    });

    const formatTime = (milliseconds) => {
        const minutes = Math.floor(milliseconds / 60);
        const seconds = milliseconds % 60;
    
        const padZero = (num) => (num < 10 ? `0${num}` : num);
    
        return `${padZero(minutes)}:${padZero(seconds)}`;
      };

    return (
        <>
            <Box
                sx={{
                    width: 228,
                    display: 'flex',
                    [theme.breakpoints.down('md')]: {
                        width: 'auto'
                    }
                }}
            >
                <Box component="span" sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1 }}>
                    <LogoSection />
                    {window.location.origin === 'https://dev-admin.unlock.fit' &&  formatTime(remaining) }
                </Box>
                <ButtonBase sx={{ borderRadius: '12px', overflow: 'hidden' }}>
                    <Avatar
                        variant="rounded"
                        sx={{
                            ...theme.typography.commonAvatar,
                            ...theme.typography.mediumAvatar,
                            transition: 'all .2s ease-in-out',
                            background: theme.palette.secondary.light,
                            color: theme.palette.secondary.dark,
                            '&:hover': {
                                background: theme.palette.secondary.dark,
                                color: theme.palette.secondary.light
                            }
                        }}
                        onClick={handleLeftDrawerToggle}
                        color="inherit"
                    >
                        <IconMenu2 stroke={1.5} size="1.3rem" />
                    </Avatar>
                </ButtonBase>
            </Box>
            {/* <SearchSection /> */}
            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ flexGrow: 1 }} />
            {/* <NotificationSection /> */}
            <ProfileSection />
        </>
    );
};

Header.propTypes = {
    handleLeftDrawerToggle: PropTypes.func
};

export default Header;
